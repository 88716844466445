import React, { useState } from "react";
import styles from "./beta.module.scss";
import Layout from "../../components/layout/layout";
import Step from "../../components/beta/step/step";
import {Alert, Col, Row} from "react-bootstrap";
import { Mutation } from 'react-apollo'
import { BETA_MUTATION } from "../../apollo/mutations/beta";

const Beta = ({ location }) => {
  const [state, setState] = useState({ name: "", email: "" });
  const [formErrors, setFormErrors] = useState({
    nameError: '',
    emailError: '',
  })

  const handleInputChange = event => {
    setState({...state, [event.target.name]: event.target.value})
  }

  const validate = () => {
    let nameError = '';
    let emailError = '';

    if (state.name.trim().length === 0) {
      nameError = `This field can't be empty`;
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (state.email.trim().length === 0) {
      emailError = `This field can't be empty`;
    } else if (!state.email.trim().match(emailRegex)){
      emailError = 'Please type in correct email address'
    }

    setFormErrors({nameError: nameError, emailError: emailError})
    return !(nameError || emailError);
  }

  return (
    <Layout location={location}>
      <Col className={styles.beta}>
        <h1>Become beta user</h1>
        <p>
          Welcome to the CELLR beta program and we couldn’t be happier to have you onboard and
          the valuable feedback you’ll be offering us.
        </p>
        <p>
          We certainly don’t want to infringe on your personal time too much, but there are
          a few things we hope to get back from you as you use CELLR.
        </p>
        <Row className={styles.beta__steps}>
          <Col xs={12} md={5} className={styles.beta__content}>
            <Step width="355px" color="#B01902" number="1." text="Please use the app with regular frequency to help us identify any bugs in the platform."/>
            <Step width="290px" color="#841002" number="2." text="Please use the app to take pictures of your inventory."/>
            <Step width="254px" color="#6E0C01" number="3." text="Please use the app to add wines and pictures to your wish list."/>
          </Col>
          <Col xs={12} md={7} className={styles.beta__content}>
            <Step width="430px" color="#7C0C07" number="4." text="Please use the app to record the wines you consume from inventory or on the go."/>
            <Step width="550px" color="#A62936" number="5." text="Please do sync your inventory from Cellar Tracker so we can start building and testing the Community inventory functionality."/>
            <Step width="350px" color="#570701" number="6." text="Please do build out your Wish List on items you don’t want to lose track of."/>
          </Col>
        </Row>
        {/*<div className={styles.beta__form}>*/}
          {/* App Store download button */}
          <a href="https://apps.apple.com/us/app/cellr-curate-your-wine-life/id1528260692?itsct=apps_box_badge&amp;itscg=30200"
             className={styles.beta__button}
          >
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1614470400&h=3ba8763583a70d41f977ebb93e7083be"
                 alt="Download on the App Store"
                 className={styles.beta__image}>
            </img>
          </a>

          {/*Disabled request access since 19/04/2021, show link to App Store*/}
          {/*<Mutation mutation={BETA_MUTATION}>*/}
          {/*  {(sendBeta, {loading, error, data}) => (*/}
          {/*    <React.Fragment>*/}

          {/*      {data && (<Alert className={styles.alert}>{data?.webBeta}</Alert>)}*/}
          {/*      {error && (<Alert className={styles.alert}>{error?.message + ' Try Again!'}</Alert>)}*/}

          {/*      <form onSubmit={async (event) => {*/}
          {/*                        event.preventDefault()*/}
          {/*                        const isValid = validate()*/}
          {/*                        if (isValid) {*/}
          {/*                          sendBeta({*/}
          {/*                            variables: {*/}
          {/*                              name: state.name,*/}
          {/*                              email: state.email,*/}
          {/*                            }*/}
          {/*                          })*/}
          {/*                        }*/}
          {/*                       }}*/}
          {/*            autoComplete="off" className={styles.beta__form__content}*/}
          {/*       >*/}
          {/*         <div>*/}
          {/*           <input type="text" name="name" placeholder="Name" value={state.name} onChange={handleInputChange}/>*/}
          {/*          { formErrors.nameError ? <span>{formErrors.nameError}</span> : null }*/}
          {/*         </div>*/}
          {/*         <div>*/}
          {/*          <input type="text" name="email" placeholder="Email" value={state.email} onChange={handleInputChange}/>*/}
          {/*          { formErrors.emailError ? <span>{formErrors.emailError}</span> : null }*/}
          {/*        </div>*/}

          {/*        <button type="submit" disabled={loading}>Send me invite</button>*/}
          {/*      </form>*/}
          {/*    </React.Fragment>*/}
          {/*  )}*/}
          {/*</Mutation>*/}
        {/*</div>*/}
        <p className={styles.beta__final}>Thanks you again for being a part of the CELLR beta program and helping in our development!</p>
      </Col>
    </Layout>
  )
};

export default Beta;
