import React from "react";
import styles from "./step.module.scss";
import styled from "styled-components"

const Title = styled.h1`
    width: ${({width}) => width};
    @media (max-width: 520px) {
        width: ${({width}) => width.slice(0, -2) > 400 ? (width.slice(0, -2) / 3) + 'px' : (width.slice(0, -2) / 2) + 'px'};
    };
    @media (min-width: 520px) and (max-width: 768px) {
        width: ${({width}) => width.slice(0, -2) > 400 ? (width.slice(0, -2) / 2) + 'px' : (width.slice(0, -2) / 1.5) + 'px'};
    }
    @media (min-width: 768px) and (max-width: 1200px) {
        width: ${({width}) => width.slice(0, -2) > 400 ? (width.slice(0, -2) / 1.5) + 'px' : width};
    }
`

// width: ${({width}) => (width.slice(0, -2) / 2) + 'px' };


const Step = ({ width, color, number, text }) => {

  return (
    <div className={styles.step} style={{backgroundImage: `linear-gradient(to right, ${color}, ${color})`}}>
      <span>{number}</span>
      <Title as="p" width={width}>{text}</Title>
    </div>
  )
}

export default Step;
