import {gql} from '@apollo/client'

export const BETA_MUTATION = gql`
    mutation WebBeta(
        $name: String!
        $email: String!
      ) {
        webBeta(
          name: $name
          email: $email
        )
      }
`
